import axios from "axios";
import { checkEnvType } from "src/helper";

const isDevelopment = checkEnvType();
const API_URL = isDevelopment ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_PROD_URL;

const getDigital = async () => {
  return await axios
    .get(API_URL + "users/digitalissues")
    .then((response) => {
      return response.data;
    });
};
const getMagazineCovers = async () => {
  try {
    const response = await axios.get(API_URL + "users/magazinecovers");
    return response.data;
  } catch (error) {
    console.error("Error fetching magazine covers:", error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
};


const DigitalService = { getDigital, getMagazineCovers };
export default DigitalService;