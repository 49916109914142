export const checkEnvType = () => {
  if (process.env.NODE_ENV === "development") {
    return true;
  } else {
    return false;
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const breakString = (str, len) => {
  const regex = new RegExp(".{" + len + "}", "g");
  const trail = str.length - (str.length % len);

  let parts = str.match(regex);
  parts.push(str.substring(trail));

  return parts.join("<br />");
};

export const textTruncate = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const UniqueArrayByCategory = (arr) => {
  const res = Object.values(
    arr.reduce((acc, current) => {
      (acc[current.category.trim()] = acc[current.category.trim()] || []).push(
        current
      );
      return acc;
    }, {})
  );
  const Arr = res.map((vl) => vl.pop());
  return Arr;
};
export const UniqueArrayByCategoryInnerPage = (arr) => {
  const res = Object.values(
    arr.reduce((acc, current) => {
      (acc[current.category.trim()] = acc[current.category.trim()] || []).push(
        current
      );
      return acc;
    }, {})
  );
  const Arr = res.map((vl) => vl.pop());
  return Arr;
};


export const confirmatonAlert = (type) => {
  const text =
    type === 0
      ? "Are you sure you wish to delete this item?"
      : "This will overide your previous Ads. Are you sure you wish to overide this item?";
  if (window.confirm(text)) {
    return true;
  } else {
    return false;
  }
};

export const calculateDays = (date) => {
  const checkDate = new Date(date);
  const todays = new Date();
  todays.setDate(todays.getDate() - 1);
  if (new Date().toDateString() == checkDate.toDateString()) {
    return "TODAY";
  } else if (todays.toDateString() == checkDate.toDateString()) {
    return "YESTERDAY";
  } else {
    const day = checkDate.getDay();
    if (day === 0) {
      return "TODAY";
    } else {
      return `${day} DAYS AGO`;
    }
  }
};

export const capiltalizeWord = (word) => {
  if (!word || typeof word !== "string") {
    return "";
  }

  return word.includes(" ")
    ? word
        .split(" ")
        .map((vl) => vl.charAt(0).toUpperCase() + vl.slice(1))
        .join(" ")
    : word.charAt(0).toUpperCase() + word.slice(1);
};

export const setUserAuthToken = (token) => {
  localStorage.removeItem("type");
  localStorage.removeItem("token");
  return localStorage.setItem("token", token);
};

export const setUserEmail = (email) => {
  localStorage.removeItem("email");
  return localStorage.setItem("email", email);
}

export const getUserEmail = () => {
  return localStorage.getItem("email");
}

export const getUserAuthToken = () => {
  return localStorage.getItem("token");
};

export const setUserLoginType = (type) => {
  return localStorage.setItem("type", type);
};

export const getUserLoginType = () => {
  return localStorage.getItem("type");
};

export const removeUserInfo = () => {
  localStorage.removeItem("type");
  localStorage.removeItem("token");
};

export const checkLoginType = (isLogin) => {
  if (isLogin === "manual") {
    return 0;
  } else if (isLogin === "google") {
    return 1;
  } else {
    return 2;
  }
};

export const mouseOverEvent = (mouse, elem) => {
  let elemBounding = elem.getBoundingClientRect();

  let elementLeftEdge = elemBounding.left;
  let elementTopEdge = elemBounding.top;
  let elementRightEdge = elemBounding.right;
  let elementBottomEdge = elemBounding.bottom;

  let mouseX = mouse.pageX;
  let mouseY = mouse.pageY;

  let topEdgeDist = Math.abs(elementTopEdge - mouseY);
  let bottomEdgeDist = Math.abs(elementBottomEdge - mouseY);
  let leftEdgeDist = Math.abs(elementLeftEdge - mouseX);
  let rightEdgeDist = Math.abs(elementRightEdge - mouseX);

  let min = Math.min(topEdgeDist, bottomEdgeDist, leftEdgeDist, rightEdgeDist);

  switch (min) {
    case leftEdgeDist:
      return 0; //left
    case rightEdgeDist:
      return 1; //right
    case topEdgeDist:
      return 2; //top
    case bottomEdgeDist:
      return 3; //bottom
  }
};

export const socialMediaContentTypes = {
  'tiktok-content': 'tiktok-content',
  'youtube-content': 'youtube-content',
  'poshh-deals': 'poshh-deals',
  'collages': 'collages'
}

export const getAddSocialMediaContentType = (type) => {

  console.log('social media',type);
  switch (type) {
    case type:
      return getSocialMediaContentTypeLabel(type.replace('add-', ''));
    default:
      return '';
  }
}

export const getSocialMediaContentTypeLabel = (type) => {
  try {
    const labels = {
      'tiktok-content': 'Tiktok Content',
      'youtube-content': 'Youtube Content',
      'poshh-deals': 'Poshh Deals', 
      'collages': 'Collages'
    };
    console.log(labels[type]);
    return labels[type] || type;
  } catch (error) {
    console.error('Error getting content type label:', error);
    return type;
  }
}
export const fetchSlugFromURL = (url) => {
  try {
    const path = url;
    const matches = path.match(/\/admin\/([^/]+)/);
    const slug = matches ? matches[1] : '';
    return slug;
  } catch (error) {
    console.error('Error extracting slug from URL:', error);
    return '';
  }
}

export const fetchSlugFromURLUser = (url) => {
  try {
    const path = url;
    const matches = path.match(/\/([^/]+)/);
    const slug = matches ? matches[1] : '';
    return slug;
  } catch (error) {
    console.error('Error extracting slug from URL:', error);
    return '';
  }
}

export const pagesWithExternalLInksText = ['magazine covers', 'deals'];
export const pagesWithExternalLInksSlug = ['magazine-covers', 'deals'];
export const twoGridCardMobilePages = ['poshh-deals'];